.swoosh {
    z-index: -1;
    position: absolute;
    &.swoosh--i1 {
        right: -315px;
        top: 400px;
        transform: rotate(5deg);
    }
    &.swoosh--i2 {
        transform: rotate(143deg);
        top: -530px;
        width: 220%;
        left: -600px;
    }
    &.swoosh--i3 {
        transform: rotate(12deg);
        width: 200%;
        top: -570px;
        left: -420px;
    }
    &.swoosh--i4 {
        width: 230%;
        top: -750px;
        left: -800px;
        transform: rotate(16deg);
        @media @h-phone {
            left: -300px;
        }
    }
    &.swoosh--legal {
        top: -250px;
        width: 230%;
        left: -650px;
        transform: rotate(5deg);
        @media @tablet {
            top: 200px;
        }
        @media @h-phone {
            top: 400px;
            left: -200px;
        }
    }
}