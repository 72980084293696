.footer {
    background-color: @lightblue;
    padding-bottom: 80px;
}

.footer__text {
    margin-top: 41px;
    margin-bottom: 0%;
    color: @white;
    font-size: .875rem;
    line-height: 1.57;
    position: relative;
    @media @h-phone {
        margin-bottom: 0;
    }
    &.footer__text--highlight {
        font-size: 1.285rem;
        font-weight: 700;
        color: @paper;
        @media @v-phone {
            font-size: 1rem;;
        }
    }
    &.footer__text--link {
        text-decoration: none;
        margin-right: 5px;
    }
}   