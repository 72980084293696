/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lato-v17-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'), url('../fonts/lato-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/lato-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/lato-v17-latin-300.woff') format('woff'), /* Modern Browsers */ url('../fonts/lato-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/lato-v17-latin-300.svg#Lato') format('svg');
  /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v17-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'), url('../fonts/lato-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/lato-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/lato-v17-latin-regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/lato-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/lato-v17-latin-regular.svg#Lato') format('svg');
  /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lato-v17-latin-700.eot');
  /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'), url('../fonts/lato-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/lato-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/lato-v17-latin-700.woff') format('woff'), /* Modern Browsers */ url('../fonts/lato-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/lato-v17-latin-700.svg#Lato') format('svg');
  /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lato-v17-latin-900.eot');
  /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'), url('../fonts/lato-v17-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/lato-v17-latin-900.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/lato-v17-latin-900.woff') format('woff'), /* Modern Browsers */ url('../fonts/lato-v17-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/lato-v17-latin-900.svg#Lato') format('svg');
  /* Legacy iOS */
}
.columns {
  display: flex;
  flex-wrap: wrap;
  margin: 60px 32px 100px;
}
.columns.columns--centered {
  justify-content: center;
}
.columns.columns--services {
  margin-bottom: 0;
  justify-content: left;
}
.columns.columns--legals {
  justify-content: center;
  margin-left: 60px;
}
.column {
  position: relative;
  padding: 0 8px 16px;
  width: 100%;
}
.column.column--8 {
  max-width: 66.6%;
}
@media only screen and (max-width: 576px) {
  .column.column--8 {
    max-width: 100%;
  }
}
.column.column--6 {
  max-width: 50%;
}
@media only screen and (max-width: 992px) {
  .column.column--6 {
    max-width: 100%;
  }
}
.column.column--5 {
  max-width: 43%;
}
@media only screen and (max-width: 992px) {
  .column.column--5 {
    max-width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .column.column--5 {
    max-width: 100%;
  }
}
.column.column--4 {
  max-width: 33.3%;
}
@media only screen and (max-width: 992px) {
  .column.column--4 {
    max-width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .column.column--4 {
    max-width: 70%;
  }
}
@media only screen and (max-width: 576px) {
  .column.column--4 {
    max-width: 100%;
  }
}
.column.column--3 {
  max-width: 25%;
}
@media only screen and (max-width: 992px) {
  .column.column--3 {
    max-width: 33.3%;
  }
}
@media only screen and (max-width: 768px) {
  .column.column--3 {
    max-width: 50%;
  }
}
@media only screen and (max-width: 430px) {
  .column.column--3 {
    max-width: 100%;
  }
}
.column.column--text {
  max-width: 33.3%;
}
@media only screen and (max-width: 992px) {
  .column.column--text {
    max-width: 75%;
  }
}
@media only screen and (max-width: 576px) {
  .column.column--text {
    max-width: 100%;
  }
}
.column.column--team {
  max-width: 25%;
}
@media only screen and (max-width: 992px) {
  .column.column--team {
    max-width: 50%;
    height: 292px;
  }
}
@media only screen and (max-width: 576px) {
  .column.column--team {
    max-width: 90%;
    height: auto;
  }
}
@media only screen and (max-width: 430px) {
  .column.column--team {
    max-width: 100%;
    height: auto;
  }
}
.column.column--team--contact {
  max-width: 35%;
}
@media only screen and (max-width: 992px) {
  .column.column--team--contact {
    max-width: 50%;
    height: 292px;
  }
}
@media only screen and (max-width: 576px) {
  .column.column--team--contact {
    max-width: 90%;
    height: auto;
  }
}
@media only screen and (max-width: 430px) {
  .column.column--team--contact {
    max-width: 100%;
    height: auto;
  }
}
.column.column--bottomright {
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  max-width: 24%;
}
@media only screen and (max-width: 992px) {
  .column.column--bottomright {
    max-width: 100%;
    margin-top: 100px;
  }
}
.column.column--centered {
  display: flex;
  justify-content: center;
}
.column.column--services {
  max-width: 22.2%;
  padding-left: 0;
}
.column.column--11 {
  max-width: 91.66666667%;
}
.column.offset--1 {
  margin-left: 8.33333333%;
}
.column.offset--10 {
  margin-left: 83.33333333%;
}
.header {
  position: relative;
  overflow: hidden;
  max-height: 700px;
}
@media only screen and (max-width: 1200px) {
  .header {
    max-height: 650px;
  }
}
@media only screen and (max-width: 992px) {
  .header {
    max-height: 550px;
  }
}
@media only screen and (max-width: 576px) {
  .header {
    max-height: 250px;
  }
}
@media only screen and (max-width: 430px) {
  .header {
    max-height: 200px;
  }
}
.header__img {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-10%);
}
@media only screen and (max-width: 992px) {
  .header__img {
    transform: translateY(0);
  }
}
@media only screen and (max-width: 576px) {
  .header__img {
    min-height: 180px;
  }
}
.header__title {
  position: absolute;
  color: white;
  bottom: 20%;
  right: 17%;
  font-weight: 900;
  font-size: 3.125em;
  display: block;
}
@media only screen and (max-width: 992px) {
  .header__title {
    font-size: 2.5em;
  }
}
@media only screen and (max-width: 768px) {
  .header__title {
    font-size: 2em;
  }
}
@media only screen and (max-width: 576px) {
  .header__title {
    font-size: 1.5rem;
    right: 20px;
  }
}
@media only screen and (max-width: 430px) {
  .header__title {
    font-size: 1.3rem;
  }
}
.header__title--upper {
  font-family: Lato;
  font-weight: 300;
  font-size: 0.8em;
  display: block;
  margin-left: 100px;
}
@media only screen and (max-width: 768px) {
  .header__title--upper {
    margin-left: 50px;
  }
}
@media only screen and (max-width: 430px) {
  .header__title--upper {
    margin-left: 25px;
  }
}
.header__title--lower {
  font-family: Lato;
  font-weight: 300;
  font-size: 0.8em;
  display: block;
  margin-left: 250px;
}
@media only screen and (max-width: 768px) {
  .header__title--lower {
    margin-left: 150px;
  }
}
@media only screen and (max-width: 430px) {
  .header__title--lower {
    margin-left: 100px;
  }
}
.header__title--item {
  display: block;
}
.header-arc {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 123%;
}
.menu {
  margin: 0;
  padding: 0;
  margin-left: auto;
}
@media only screen and (max-width: 768px) {
  .menu {
    transition: 0.6s;
    position: absolute;
    top: -250%;
    z-index: -1;
    left: 0;
    right: 0;
    height: 125px;
    background: rgba(255, 255, 255, 0.9);
  }
  .menu .item {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .menu.menu--open {
    display: block;
    top: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
  }
}
.menu.menu--open .link {
  color: #4B6580;
}
.item {
  display: inline-flex;
  list-style: none;
  margin-left: 10px;
  transition: 0.4s;
}
@media only screen and (max-width: 768px) {
  .item {
    font-size: 1.5rem;
    margin: 10px 38px;
  }
}
.item::after {
  content: "|";
  position: relative;
  display: block;
  color: #fff;
  z-index: 99;
  margin-left: 10px;
  transition: 0.4s;
}
@media only screen and (max-width: 768px) {
  .item::after {
    display: none;
  }
}
.item:last-child::after {
  display: none;
}
.item.item--alt::after {
  color: #4B6580;
}
.link {
  color: #fff;
  text-decoration: none;
  transition: 0.4s;
}
@media only screen and (max-width: 768px) {
  .link {
    margin: 10px 0;
  }
}
.link.link--alt {
  color: #4B6580;
}
.link.link--inline {
  color: #707070;
}
.menu-meta {
  margin: 5px;
  padding: 0;
  margin-right: 0.5em;
}
.menu-meta__item {
  list-style: none;
  display: inline-flex;
  margin-left: 5px;
}
@media only screen and (max-width: 992px) {
  .menu-meta__item {
    margin: 10px;
    font-size: 1.4em;
  }
}
.burger {
  display: none;
  color: #4B6580;
}
@media only screen and (max-width: 768px) {
  .burger {
    display: inline;
  }
}
.burger:hover .test {
  display: grid;
}
@media only screen and (max-width: 576px) {
  .contact {
    display: none;
  }
}
.navbar-wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  z-index: 999;
  transition: 0.4s;
}
.navbar-wrap.navbar-wrap--solid {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1088px;
  width: 100%;
  margin: 0 auto;
}
.navbar__link {
  display: block;
  position: relative;
  max-width: 360px;
  width: 100%;
  height: auto;
  transition: width 0.4s ease;
}
@media only screen and (max-width: 992px) {
  .navbar__link {
    max-width: 300px;
  }
}
.navbar__logo {
  transition: 0.4s;
  width: 100%;
}
.main {
  max-width: 1088px;
  margin: 0 auto;
}
.title {
  position: relative;
  color: #4B6580;
  font-size: 3.75rem;
  font-weight: 900;
  display: block;
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .title {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 430px) {
  .title {
    font-size: 1.5rem;
  }
}
.title.title--light {
  font-size: 1.5625rem;
  font-weight: 300;
  color: #D0D0C8;
}
@media only screen and (max-width: 430px) {
  .title.title--light {
    font-size: 1.5rem;
  }
}
.title.title--legalnotice {
  margin-top: 147px;
  text-align: left;
}
.title.title--icon {
  color: #4B6580;
  font-size: 2rem;
  margin-top: 165px;
  margin-left: 50px;
  display: inline-block;
  position: absolute;
}
.text {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.57;
  color: #707070;
  position: relative;
  hyphens: auto;
}
.text.text--columns {
  column-count: 2;
  padding: 0;
}
@media only screen and (max-width: 992px) {
  .text.text--columns {
    column-count: 1;
    padding: 0;
  }
}
.text.text--highlight {
  font-size: 1rem;
  font-weight: 700;
  color: #4B6580;
  padding: 0;
}
@media only screen and (max-width: 768px) {
  .text.text--highlight {
    font-size: 0.9em;
  }
}
@media only screen and (max-width: 576px) {
  .text.text--highlight {
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 430px) {
  .text.text--highlight {
    font-size: 0.54em;
  }
}
.text.text--name {
  font-size: 1.3rem;
  font-weight: 700;
  color: #4B6580;
  padding: 0;
}
.text.text--link {
  color: #4B6580;
  text-decoration: none;
  padding: 0;
}
.text.text--hiddenlink {
  margin-top: 40px;
  font-size: 0.75rem;
  text-decoration: none;
  color: #D0D0C8;
  display: flex;
  justify-content: flex-end;
}
.text.text--hiddenlink img {
  margin-right: 5px;
  margin-bottom: 70px;
}
.text.text--expanded {
  padding: 0 22px;
}
@media only screen and (max-width: 576px) {
  .text.text--expanded {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 430px) {
  .text.text--expanded {
    font-size: 0.7rem;
  }
}
.text.text--icon {
  color: #4B6580;
  display: inline;
}
.text.text--covering {
  position: absolute;
  top: 50%;
  width: 80%;
  margin: 0 33px;
  padding: 0;
}
@media only screen and (max-width: 992px) {
  .text.text--covering {
    font-size: 1rem;
  }
}
.text.text--services {
  column-count: 2;
  padding: 0;
}
@media only screen and (max-width: 992px) {
  .text.text--services {
    column-count: 1;
  }
}
@media only screen and (max-width: 768px) {
  .text.text--services {
    padding: 0;
  }
}
.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.image.image--small {
  filter: grayscale(100%);
}
.image.image--team {
  max-width: 275px;
  top: 0;
  object-position: top;
}
@media only screen and (max-width: 992px) {
  .image.image--team {
    width: 275px;
    height: 275px;
  }
}
@media only screen and (max-width: 768px) {
  .image.image--team {
    width: 225px;
    height: 225px;
  }
}
.list {
  color: #707070;
  font-size: 0.875em;
  line-height: 1.57;
  padding-left: 20px;
}
.list.list--highlight {
  font-weight: 900;
  color: #4B6580;
  list-style-type: #4B6580;
}
.list.list--services {
  list-style: none;
  color: #fff;
  font-size: 1.125rem;
  margin: 20px;
  height: 100%;
}
@media only screen and (max-width: 430px) {
  .list.list--services {
    font-size: 0.8em;
    margin: 0;
    padding: 0;
  }
}
.list.list--services li {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}
.visible {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.abs-tl {
  position: absolute;
  top: 0;
  left: 0;
}
.footer {
  background-color: #6C7F91;
  padding-bottom: 80px;
}
.footer__text {
  margin-top: 41px;
  margin-bottom: 0%;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.57;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .footer__text {
    margin-bottom: 0;
  }
}
.footer__text.footer__text--highlight {
  font-size: 1.285rem;
  font-weight: 700;
  color: #D0D0C8;
}
@media only screen and (max-width: 576px) {
  .footer__text.footer__text--highlight {
    font-size: 1rem;
  }
}
.footer__text.footer__text--link {
  text-decoration: none;
  margin-right: 5px;
}
.services__decoration {
  font-size: 0.875rem;
  color: rgba(208, 208, 200, 0.75);
  font-style: italic;
  font-weight: 700;
}
.services__decoration::before {
  content: " ";
  height: 2px;
  width: 76px;
  background-color: rgba(208, 208, 200, 0.75);
  display: inline-block;
  margin-right: 17px;
}
.service__title {
  display: block;
  position: relative;
  font-size: 3.125rem;
  font-weight: 900;
  color: #4B6580;
  text-align: left;
  margin-top: 21px;
  margin-bottom: 0.37em;
}
.service__title.service__title--small {
  font-size: 1.5625rem;
}
@media only screen and (max-width: 576px) {
  .service__title.service__title--small {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 768px) {
  .service__title.service__title--small {
    font-size: 2.325rem;
  }
}
@media only screen and (max-width: 576px) {
  .service__title.service__title--small {
    font-size: 1.7rem;
  }
}
@media only screen and (max-width: 430px) {
  .service__title.service__title--small {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 576px) {
  .service__title {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 430px) {
  .service__title {
    font-size: 1.9rem;
  }
}
.background {
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 16px;
  left: 8px;
}
.background img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlay {
  background-color: rgba(75, 101, 128, 0.9);
  width: 63.63636364%;
  display: block;
  position: relative;
  z-index: 5;
  padding: 20px;
  margin-left: 36.36363636%;
}
@media only screen and (max-width: 768px) {
  .overlay {
    margin-left: 0;
    width: 100%;
  }
}
.check {
  margin-right: 16px;
  flex-shrink: 0;
}
.check:first-child {
  color: #D0D0C8;
}
@media only screen and (max-width: 576px) {
  .check {
    font-size: 1em;
  }
}
.icon--fixed {
  color: #4B6580;
  font-size: 2rem;
  position: fixbtn--legal;
  top: 49px;
  left: 50%;
  transform: translateX(-544px);
  display: block;
}
@media only screen and (max-width: 1200px) {
  .services__footer {
    margin-right: 8.33333333%;
  }
}
.back--services {
  position: fixed;
  font-size: 2.8em;
  color: #4B6580;
  top: 45px;
  left: 3%;
}
.box {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  padding-bottom: 82.4%;
  transition: all 0.4s ease;
}
.box .hidden {
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.4s ease;
}
.box:hover {
  z-index: 99;
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.5);
}
@media only screen and (max-width: 768px) {
  .box:hover {
    box-shadow: none;
  }
}
.box:hover .hidden {
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  .box:hover .hidden {
    display: none;
  }
}
.box:hover .arrow {
  display: none;
}
@media only screen and (max-width: 768px) {
  .box:hover .arrow {
    display: block;
  }
}
.box__title {
  position: relative;
  font-size: 1.9em;
  font-weight: 900;
  display: block;
  margin: 39px 31px 34px 31px;
  text-align: left;
  color: #4B6580;
}
@media only screen and (max-width: 576px) {
  .box__title {
    font-size: 2em;
  }
}
@media only screen and (max-width: 430px) {
  .box__title {
    font-size: 1.4em;
  }
}
.box__title.box__title--off {
  color: #fff;
}
.arrow {
  position: absolute;
  bottom: 10%;
  left: 10%;
}
.image__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(157, 170, 183);
  mix-blend-mode: multiply;
}
.overlay-arc {
  object-fit: cover;
  position: absolute;
  top: 0;
}
.swoosh {
  z-index: -1;
  position: absolute;
}
.swoosh.swoosh--i1 {
  right: -315px;
  top: 400px;
  transform: rotate(5deg);
}
.swoosh.swoosh--i2 {
  transform: rotate(143deg);
  top: -530px;
  width: 220%;
  left: -600px;
}
.swoosh.swoosh--i3 {
  transform: rotate(12deg);
  width: 200%;
  top: -570px;
  left: -420px;
}
.swoosh.swoosh--i4 {
  width: 230%;
  top: -750px;
  left: -800px;
  transform: rotate(16deg);
}
@media only screen and (max-width: 768px) {
  .swoosh.swoosh--i4 {
    left: -300px;
  }
}
.swoosh.swoosh--legal {
  top: -250px;
  width: 230%;
  left: -650px;
  transform: rotate(5deg);
}
@media only screen and (max-width: 992px) {
  .swoosh.swoosh--legal {
    top: 200px;
  }
}
@media only screen and (max-width: 768px) {
  .swoosh.swoosh--legal {
    top: 400px;
    left: -200px;
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
html,
body {
  box-sizing: border-box;
}
img {
  display: block;
  position: relative;
}
.body {
  font-family: Lato;
}
.overflow {
  position: relative;
  overflow-x: hidden;
}
.jumplink {
  height: 60px;
  position: relative;
}
.mb-0 {
  margin-bottom: 0;
}
.pos-rel {
  position: relative;
  width: 100%;
}
.navcloser {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}
.hidden {
  display: none;
}
.button {
  background: #6c7f91;
  background: linear-gradient(to bottom right, #6c7f91 0%, #465d76 100%);
  appearance: none;
  border: 0;
  color: #fff;
  padding: 10px 12px;
  font-size: 0.75em;
  font-weight: 300;
  cursor: pointer;
}
.button.button--wide {
  width: 100%;
  text-align: center;
  display: block;
  color: #fff;
  text-decoration: none;
}
.expands__down {
  position: relative;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  background-color: #fff;
  opacity: 0.3;
  transition: all 0.4s ease;
}
.expands__down .image--small {
  border: 0.5px solid rgba(112, 112, 112, 0.32);
  transition: all 0.4s ease;
}
.expands__down .hidden {
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  box-shadow: 0 0 11px 2px rgba(0, 0, 0, 0.5);
  transition: all 1s ease;
}
.expands__down:hover {
  z-index: 99;
  opacity: 1;
}
.expands__down:hover .image--small {
  border: 0.5px solid #fff;
}
.expands__down:hover .hidden {
  opacity: 1;
  visibility: visible;
}
.expands__down:hover .image--small {
  filter: grayscale(0);
  opacity: 1;
}
.back--legal {
  position: fixed;
  font-size: 2.8em;
  color: #4B6580;
  top: 217px;
  left: 50px;
}
@media only screen and (max-width: 992px) {
  .back--legal {
    top: 211px;
  }
}
@media only screen and (max-width: 768px) {
  .back--legal {
    top: 204px;
  }
}
@media only screen and (max-width: 576px) {
  .back--legal {
    left: 10px;
  }
}
@media only screen and (max-width: 430px) {
  .back--legal {
    top: 195px;
  }
}
