.columns {
    display: flex;
    flex-wrap: wrap;
    margin: 60px 32px 100px;

    &.columns--centered {
        justify-content: center;
    }

    &.columns--services {
        margin-bottom: 0;
        justify-content: left;
    }

    &.columns--legals {
        justify-content: center;
        margin-left: 60px;
    }
}

.column {
    position: relative;
    padding: 0 8px 16px;
    width: 100%;

    &.column--8 {
        max-width: 66.6%;

        @media @v-phone {
            max-width: 100%;
        }
    }

    &.column--6 {
        max-width: 50%;

        @media @tablet {
            max-width: 100%;
        }
    }

    &.column--5 {
        max-width: 43%;

        @media @tablet {
            max-width: 50%;
        }

        @media @h-phone {
            max-width: 100%;
        }
    }

    &.column--4 {
        max-width: 33.3%;

        @media @tablet {
            max-width: 50%;
        }

        @media @h-phone {
            max-width: 70%;
        }

        @media @v-phone {
            max-width: 100%;
        }
    }

    &.column--3 {
        max-width: 25%;

        @media @tablet {
            max-width: 33.3%;
        }

        @media @h-phone {
            max-width: 50%;
        }

        @media @v-phone--s {
            max-width: 100%;
        }
    }

    &.column--text {
        max-width: 33.3%;

        @media @tablet {
            max-width: 75%;
        }

        @media @v-phone {
            max-width: 100%;
        }
    }

    &.column--team {
        max-width: 25%;

        @media @tablet {
            max-width: 50%;
            height: 292px;
        }

        @media @v-phone {
            max-width: 90%;
            height: auto;
        }

        @media @v-phone--s {
            max-width: 100%;
            height: auto;
        }
    }

    &.column--team--contact {
        max-width: 35%;

        @media @tablet {
            max-width: 50%;
            height: 292px;
        }

        @media @v-phone {
            max-width: 90%;
            height: auto;
        }

        @media @v-phone--s {
            max-width: 100%;
            height: auto;
        }
    }

    &.column--bottomright {
        align-items: flex-end;
        justify-content: flex-end;
        display: flex;
        max-width: 24%;

        @media @tablet {
            max-width: 100%;
            margin-top: 100px;
        }
    }

    &.column--centered {
        display: flex;
        justify-content: center;
    }

    &.column--services {
        max-width: 22.2%;
        padding-left: 0;
    }

    &.column--11 {
        max-width: (11/12*100%);
    }

    &.offset--1 {
        margin-left: (1/12*100%);
    }

    &.offset--10 {
        margin-left: (10/12*100%);
    }
}