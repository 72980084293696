.services__decoration {
    font-size: .875rem;
    color: @semigrey;
    font-style: italic;
    font-weight: 700;
    &::before {
        content: " ";
        height: 2px;
        width: 76px;
        background-color: @semigrey;
        display: inline-block;
        margin-right: 17px;
    }
}
.service__title {
    display: block;
    position: relative;
    font-size: 3.125rem;
    font-weight: 900;
    color: @blue;
    text-align: left;
    margin-top: 21px;
    margin-bottom: .37em;
    &.service__title--small {
        font-size: 1.5625rem;
        @media @v-phone {
            font-size: 1.4rem;
        }
        @media @h-phone {
            font-size: 2.325rem;
        }
        @media @v-phone {
            font-size: 1.7rem;
        }
        @media @v-phone--s {
            font-size: 1.125rem;
        }
    }
    @media @v-phone {
        font-size: 2.5rem;
    }
    @media @v-phone--s {
        font-size: 1.9rem;
    }
}
.background {
    position: absolute;
    top: 0;
    right: 8px;  
    bottom: 16px;
    left: 8px;  
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.overlay {
    background-color: @opablue;
    width: (7/11*100%);
    display: block;
    position: relative;
    z-index: 5;
    padding: 20px;
    margin-left: (4/11*100%);
    @media @h-phone {
        margin-left: 0;
        width: 100%;
    }
}
.check {
    margin-right: 16px;
    flex-shrink: 0;
    &:first-child {
        color: @paper;
    }
    @media @v-phone {
       font-size: 1em;
    }
}
.icon--fixed {
    color: @blue;
    font-size: 2rem;
    position: fixbtn--legal;
    top: 49px;
    left: 50%;
    transform: translateX((-1 * @grid-width / 2));
    display: block;
}
.services__footer {
    @media @laptop {
        margin-right: (1/12*100%);
    }
}
    
.back--services {
    position: fixed;
    font-size: 2.8em;
    color: @blue;
    top: 45px;
    left: 3%;
}