.main {
    max-width: @grid-width;
    margin: 0 auto;
}   

.title {
    position: relative;
    color: @blue;
    font-size: 3.75rem;
    font-weight: 900;
    display: block;
    text-align: center;
    @media @tablet {
        font-size: 3rem;
    }
    @media @h-phone {
        font-size: 2.5rem;
    }
    @media @v-phone--s {
        font-size: 1.5rem;
    }
    &.title--light {
        font-size: 1.5625rem;
        font-weight: 300;
        color: @paper;
        @media @v-phone--s {
            font-size: 1.5rem;
        }
    }

    &.title--legalnotice {
        margin-top: 147px;
        text-align: left;
    }
    &.title--icon {
        color: @blue;
        font-size: 2rem;
        margin-top: 165px;
        margin-left: 50px;
        display: inline-block;
        position: absolute;
    }
}

.text {
    display: block;
    width: 100%;
    font-size: .875rem;
    line-height: 1.57;
    color: @grey;
    position: relative;
    hyphens: auto;
    &.text--columns {
        column-count: 2;
        padding: 0;
        @media @tablet {
            column-count: 1;
            padding: 0;
        }
    }
    &.text--highlight {
        font-size: 1rem;
        font-weight: 700;
        color: @blue;
        padding: 0;
        @media @h-phone {
        font-size: .9em;
        }
        @media @v-phone {
            font-size: .8em;
        }
        @media @v-phone--s {
            font-size: .54em;
        }
    }
    &.text--name {
        font-size: 1.3rem;
        font-weight: 700;
        color: @blue;
        padding: 0;
    }
    &.text--link {
        color: @blue;
        text-decoration: none;
        padding: 0;
    }
    &.text--hiddenlink {
        margin-top: 40px;
        font-size: .75rem;
        text-decoration: none;
        color: @paper;
        display: flex;
        justify-content: flex-end;
        img {
            margin-right: 5px;
            margin-bottom: 70px;
        }
    }   
    &.text--expanded {
        padding: 0 22px;
        @media @v-phone {
            font-size: .8rem;
        }
        @media @v-phone--s {
            font-size: .7rem;
        }
    }   
    &.text--icon {
        color: @blue;
        display: inline;
    }
    &.text--covering {
        position: absolute;
        top: 50%;
        width: 80%;
        margin: 0 33px;
        padding: 0;
        @media @tablet {
            font-size: 1rem;
        }
    }
    &.text--services {
        column-count: 2;
        padding: 0;
        @media @tablet {
            column-count: 1;
        }
        @media @h-phone {
            padding: 0;
        }
    }
}

.image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    &.image--small {
        filter: grayscale(100%);
    }
    &.image--team {
        max-width: 275px;
        top: 0;
        object-position: top;
        @media @tablet {
        width: 275px;
        height: 275px;
        }
        @media @h-phone {
        width: 225px;
        height: 225px;
        }
    }
}

.list {
    color: @grey;
    font-size: .875em;
    line-height: 1.57;
    padding-left: 20px;
    &.list--highlight {
        font-weight: 900;
        color: @blue;
        list-style-type: @blue;
    }
    &.list--services {
        list-style: none;
        color: @white;
        font-size: 1.125rem;
        margin: 20px;
        height: 100%;
        @media @v-phone--s {
            font-size: .8em;
            margin:  0;
            padding: 0;
        }
        li {
            align-items: center;
            display: flex;
            margin-bottom: 10px;
        }
    }
}
.visible {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}


.abs-tl {
    position: absolute;
    top: 0;
    left: 0;
}