.navbar-wrap {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    z-index: 999;
    transition: .4s;
    &.navbar-wrap--solid {
        background-color: @white;
        box-shadow: 0 3px 6px @opablack;
    }
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: @grid-width;
    width: 100%;
    margin: 0 auto;
}

.navbar__link {
    display: block;
    position: relative;
    max-width: 360px;
    width: 100%;
    height: auto;
    transition: width .4s ease;
    @media @tablet {
        max-width: 300px;
    }
}

.navbar__logo {
    transition: .4s;
    width: 100%;
}