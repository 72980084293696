.box {
    position: relative;
    display: block;
    height: 0;
    width: 100%;
    padding-bottom: 82.4%;
    transition: all .4s ease;
    .hidden {
        display: block;
        position: absolute;
        background-color: @opawhite;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all .4s ease;
    }
    &:hover {
        z-index: 99;
        box-shadow: 0px 0px 11px 2px rgba(0,0,0,0.5);
        @media @h-phone {
            box-shadow: none;
        }
        .hidden {
            opacity: 1;
            @media @h-phone {
                display: none;
            }
        }
        .arrow {
            display: none;
            @media @h-phone {
                display: block;
            }
        }
    }
}

.box__title {
    position: relative;
    font-size: 1.9em;
    font-weight: 900;
    display: block;
    margin: 39px 31px 34px 31px;
    text-align: left;
    color: @blue;
    @media @v-phone {
        font-size: 2em;
    }
     @media @v-phone--s {
        font-size: 1.4em;
    }
    &.box__title--off {
        color: @white;
    }
}

.arrow {
    position: absolute;
    bottom: 10%;
    left: 10%;
}

.image__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: @boxblue;
    mix-blend-mode: multiply;
}

.overlay-arc {
    object-fit: cover;
    position: absolute;
    top: 0;
}