@import "modules/variables";
@import "modules/fonts";
@import "modules/columns";
@import "modules/header";
@import "modules/menu";
@import "modules/navbar";
@import "modules/main";
@import "modules/footer";
@import "modules/services.less";
@import "modules/box.less";
@import "modules/decoration.less";

*, *:before, *:after {
    box-sizing: inherit;
}

html, body {
    box-sizing: border-box;
}

img {
    display: block;
    position: relative;
}

.body {
    font-family: Lato;
}
.overflow {
    position: relative;
    overflow-x: hidden;
}

.jumplink {
    height: 60px;
    position: relative;
}

.mb-0 {
    margin-bottom: 0;
}
.pos-rel {
    position: relative;
    width: 100%;
}
.navcloser {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
}
.hidden {
    display: none;
}


.button {
    background: rgb(108,127,145);
    background: linear-gradient(to bottom right, rgba(108,127,145,1) 0%, rgba(70,93,118,1) 100%);
    appearance: none;
    border: 0;
    color: @white;
    padding: 10px 12px;
    font-size: .75em;
    font-weight: 300;
    cursor: pointer;
    &.button--wide {
        width: 100%;
        text-align: center;
        display: block;
        color: @white;
        text-decoration: none;
    }
}

.expands__down {
    position: relative;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    background-color: @white;
    opacity: 0.3;
    transition: all .4s ease;
    .image--small {
        border: .5px solid @opagrey;
        transition: all .4s ease;
    }
    .hidden {
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        top: 0;
        opacity: 0;
        visibility: hidden;
        background-color: #fff;
        box-shadow: 0 0 11px 2px rgba(0,0,0,.5);
        transition: all 1s ease;
    }
    &:hover {
        z-index: 99;
        opacity: 1;
        .image--small {
            border: .5px solid @white;
        }
        .hidden {
            opacity: 1;
            visibility: visible;
        }
        .image--small {
            filter: grayscale(0);
            opacity: 1;
        }
    }
}

.back--legal {
    position: fixed;
    font-size: 2.8em;
    color: @blue;
    top: 217px;
    left: 50px;
    @media @tablet {
        top: 211px;
    }
    @media @h-phone {       
        top: 204px;
    }
    @media @v-phone {
        left: 10px;  
    }
    @media @v-phone--s {
        top: 195px;
    }
}
