.menu {
    margin: 0;
    padding: 0;
    margin-left: auto;
    @media @h-phone {
        transition: .6s;
        position: absolute;
        top: -250%;
        z-index: -1;
        left: 0;
        right: 0;
        height: 125px;
        background: @opawhite;
        .item {
            display: block;
        }
    }
    &.menu--open {
        @media @h-phone {
            display: block;
            top: 100%;
            box-shadow: 0 3px 6px @opablack;
        }
        .link {
            color: @blue;
        }
    }
}

.item {
    display: inline-flex;
    list-style: none;
    margin-left: 10px;
    transition: .4s;
    @media @h-phone {
        font-size: 1.5rem;
        margin: 10px 38px;
    }
    &::after {
        content: "|";
        position: relative;
        display: block;
        color: @white;
        z-index: 99;
        margin-left: 10px;
        transition: .4s;
        @media @h-phone {
            display: none;
        }
    }
    &:last-child {
        &::after {
            display: none;
        }
    }
    &.item--alt {
        &::after {
            color: @blue;
        }
    }
}


.link {
    color: @white;
    text-decoration: none;
    transition: .4s;
    @media @h-phone {
        margin: 10px 0;
    }
    &.link--alt {
        color: @blue;
    }
    &.link--inline {
        color: @grey;
    }
}

.menu-meta {
    margin: 5px;
    padding: 0;
    margin-right: .5em;
}
.menu-meta__item {
    list-style: none;
    display: inline-flex;
    margin-left: 5px;
    @media @tablet {
        margin: 10px;
        font-size: 1.4em;
    }
}

.burger {
    display: none;
    color: @blue;
    @media @h-phone {
        display: inline;
    }
    &:hover {
        .test {
            display: grid;
        }
    }
}
.contact {
    @media @v-phone {
        display: none;
    }
}