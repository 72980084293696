.header {
    position: relative;
    overflow: hidden;
    max-height: 700px;
    @media @laptop {
        max-height: 650px;
    }
    @media @tablet {
        max-height: 550px;
    }
    @media @v-phone {
        max-height: 250px;
    }
    @media @v-phone--s {
        max-height: 200px;
    }
}

.header__img {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-10%);
    @media @tablet {
        transform: translateY(0);
    }
    @media @v-phone {
      min-height: 180px;
    }
}

.header__title {
    position: absolute;
    color: white;
    bottom: 20%;
    right: 17%;
    font-weight: 900;
    font-size: 3.125em;
    display: block;
    @media @tablet {
        font-size: 2.5em;
    }
    @media @h-phone {
        font-size: 2em;
    }
    @media @v-phone {
        font-size: 1.5rem;
        right: 20px;
    }
    @media @v-phone--s {
        font-size: 1.3rem;
    }
}   

.header__title--upper {
    font-family: Lato;
    font-weight: 300;
    font-size: .8em;
    display: block;
    margin-left: 100px;
    @media @h-phone {
        margin-left: 50px;
    }
    @media @v-phone--s {
        margin-left: 25px;
    }
}

.header__title--lower {
    font-family: Lato;
    font-weight: 300;
    font-size: .8em;
    display: block;
    margin-left: 250px;
    @media @h-phone {
        margin-left: 150px;
    }
    @media @v-phone--s {
        margin-left: 100px;
    }
}

.header__title--item {
    display: block;
}

.header-arc {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 123%;
}